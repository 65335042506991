export const APP_URL = process.env.NODE_ENV === 'production' ? 'https://thedamnation.co.uk' : 'http://localhost'

export const FETCH_ARTICLES_PENDING = 'FETCH_ARTICLES_PENDING'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_ERROR = 'FETCH_ARTICLES_ERROR'
export const FETCH_ARTICLE_PENDING = 'FETCH_ARTICLE_PENDING'
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_ERROR = 'FETCH_ARTICLE_ERROR'
export const FETCH_EXTERNAL_FILE_PENDING = 'FETCH_EXTERNAL_FILE_PENDING'
export const FETCH_EXTERNAL_FILE_SUCCESS = 'FETCH_EXTERNAL_FILE_SUCCESS'
export const FETCH_EXTERNAL_FILE_ERROR = 'FETCH_EXTERNAL_FILE_ERROR'
export const FETCH_MAIN_ARTICLE_PENDING = 'FETCH_MAIN_ARTICLE_PENDING'
export const FETCH_MAIN_ARTICLE_SUCCESS = 'FETCH_MAIN_ARTICLE_SUCCESS'
export const FETCH_MAIN_ARTICLE_ERROR = 'FETCH_MAIN_ARTICLE_ERROR'
export const RESET_ARTICLE_FETCHED_STATE = 'RESET_ARTICLE_FETCHED_STATE'
export const RESET_MAIN_ARTICLE_STATE = 'RESET_MAIN_ARTICLE_STATE'
export const RESET_DATE = 'RESET_DATE'
export const SET_DATE = 'SET_DATE'
export const SET_ARTICLE_TO_VIEW = 'SET_ARTICLE_TO_VIEW'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'

export const SAVE_ARTICLE_CONTENT = 'SAVE_ARTICLE_CONTENT'
export const SAVE_ARTICLE_EXTRA_INFO = 'SAVE_ARTICLE_EXTRA_INFO'
export const SAVE_ARTICLE_TITLE = 'SAVE_ARTICLE_TITLE'
export const SAVE_ARTICLE_AUTHOR = 'SAVE_ARTICLE_AUTHOR'
export const SAVE_ARTICLE_IMG_URL = 'SAVE_ARTICLE_IMG_URL'
export const SAVE_ARTICLE_SUMMARY = 'SAVE_ARTICLE_SUMMARY'
export const SAVE_ARTICLE_IS_MAIN = 'SAVE_ARTICLE_IS_MAIN'
export const SET_IS_EXTERNAL_FILE = 'SET_IS_EXTERNAL_FILE'
export const SET_FILE_UPLOADED = 'SET_FILE_UPLOADED'
export const SAVE_ARTICLE_FILE_PENDING = 'SAVE_ARTICLE_FILE_PENDING'
export const SAVE_ARTICLE_FILE_SUCCESS = 'SAVE_ARTICLE_FILE_SUCCESS'
export const SAVE_ARTICLE_FILE_ERROR = 'SAVE_ARTICLE_FILE_ERROR'
export const EDIT_ARTICLE_FILE_PENDING = 'EDIT_ARTICLE_FILE_PENDING'
export const EDIT_ARTICLE_FILE_SUCCESS = 'EDIT_ARTICLE_FILE_SUCCESS'
export const EDIT_ARTICLE_FILE_ERROR = 'EDIT_ARTICLE_FILE_ERROR'
export const SUBMIT_CREATE_ARTICLE_PENDING = 'SUBMIT_CREATE_ARTICLE_PENDING'
export const SUBMIT_CREATE_ARTICLE_SUCCESS = 'SUBMIT_CREATE_ARTICLE_SUCCESS'
export const SUBMIT_CREATE_ARTICLE_ERROR = 'SUBMIT_CREATE_ARTICLE_ERROR'
export const DELETE_ARTICLE_FILE_PENDING = 'DELETE_ARTICLE_FILE_PENDING'
export const DELETE_ARTICLE_FILE_SUCCESS = 'DELETE_ARTICLE_FILE_SUCCESS'
export const DELETE_ARTICLE_FILE_ERROR = 'DELETE_ARTICLE_FILE_ERROR'
export const DELETE_ARTICLE_PENDING = 'DELETE_ARTICLE_PENDING'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_ERROR = 'DELETE_ARTICLE_ERROR'
export const SELECT_ARTICLE_TO_EDIT = 'SELECT_ARTICLE_TO_EDIT'
export const RESET_SUBMIT = 'RESET_SUBMIT'
export const RESET_ARTICLE_FORM = 'RESET_ARTICLE_FORM'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'

export const LOGIN_PENDING = 'LOGIN_PENDING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_PENDING = 'LOGOUT_PENDING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const FETCH_ARTICLE_DATES_PENDING = 'FETCH_ARTICLE_DATES_PENDING'
export const FETCH_ARTICLE_DATES_SUCCESS = 'FETCH_ARTICLE_DATES_SUCCESS'
export const FETCH_ARTICLE_DATES_ERROR = 'FETCH_ARTICLE_DATES_ERROR'
export const SET_LOCATION = 'SET_LOCATION'

export const FETCH_CONTENT_PENDING = 'FETCH_CONTENT_PENDING'
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS'
export const FETCH_CONTENT_ERROR = 'FETCH_CONTENT_ERROR'
export const SUBMIT_EDIT_CONTENT_PENDING = 'SUBMIT_EDIT_CONTENT_PENDING'
export const SUBMIT_EDIT_CONTENT_SUCCESS = 'SUBMIT_EDIT_CONTENT_SUCCESS'
export const SUBMIT_EDIT_CONTENT_ERROR = 'SUBMIT_EDIT_CONTENT_ERROR'
export const SET_CONTENT_TO_EDIT = 'SET_CONTENT_TO_EDIT'
export const SAVE_CONTENT_TITLE = 'SAVE_CONTENT_TITLE'
export const SAVE_CONTENT_TEXT = 'SAVE_CONTENT_TEXT'
export const RESET_CONTENT_STATE = 'RESET_CONTENT_STATE'
